<template>
  <b-container class="col-xl-6 col-lg-9 mt-3">
    <div class="text-center">
      <h1 class="mt-5 mb-4">
        {{ $t('titles.pricing_plans') }}
      </h1>
      <p></p>
    </div>
    <b-row class="pricing-card">
      <b-col md="6">
        <b-card
            align="center"
            class="free-plan-container"
        >
          <!-- img -->
          <feather-icon icon="UserIcon" class="mb-2 mt-5" size="60"></feather-icon>
          <!--/ img -->
          <h3>{{ $t('subscriptions.free_plan') }}</h3>
          <b-card-text>
            {{ $t('subscriptions.a_plan_that_fits_everyone_needs') }}
          </b-card-text>

          <!-- annual plan -->
          <div class="annual-plan">
            <div class="plan-price mt-2">
              <sup class="font-medium-1 font-weight-bold text-primary">€</sup>
              <span class="pricing-basic-value font-weight-bolder text-primary">0</span>
              <sub class="pricing-duration text-body font-medium-1 font-weight-bold">{{
                  $t('subscriptions.month')
                }}</sub>
            </div>
          </div>
          <!--/ annual plan -->

          <!-- plan benefit -->
          <b-list-group class="list-group-circle text-left">
            <b-list-group-item>{{ $t('subscriptions.unlimited_favorites') }}</b-list-group-item>
            <b-list-group-item>{{ $t('subscriptions.unlimited_history_price') }}</b-list-group-item>
            <b-list-group-item>{{ $t('subscriptions.product_reviews') }}</b-list-group-item>
            <b-list-group-item>{{ $t('subscriptions.website_indexing_requests') }}</b-list-group-item>
            <b-list-group-item>{{ $t('subscriptions.chrome_extension') }}</b-list-group-item>
            <b-list-group-item>{{ $t('subscriptions.and_more_to_come_soon') }}</b-list-group-item>
          </b-list-group>
          <!--/ plan benefit -->

          <!-- buttons -->
          <b-button
              v-if="!loggedIn"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              block
              class="mt-2"
              variant="primary"
              :to="{name: 'auth.register'}"
          >
            {{ $t('buttons.register_now') }}
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
            align="center"
            class="paid-plan-container mt-2"
        >
          <b-overlay show class="p-1" rounded>
            <template #overlay>
              <h2 class="text-primary">{{ $t('subscriptions.coming_soon') }}</h2>
            </template>
            <!-- img -->
            <feather-icon icon="HomeIcon" class="mb-2 mt-5" size="60"></feather-icon>
            <!--/ img -->
            <h3>{{ $t('subscriptions.paid_plan') }}</h3>
            <b-card-text>
              {{ $t('subscriptions.for_pros_and_companies') }}
            </b-card-text>

            <!-- plan benefit -->
            <b-list-group class="list-group-circle text-left">
              <b-list-group-item>{{ $t('subscriptions.api_access') }}</b-list-group-item>
              <b-list-group-item>{{ $t('subscriptions.automation_scripts') }}</b-list-group-item>
              <b-list-group-item>{{ $t('subscriptions.dropshipping_connections') }}</b-list-group-item>
              <b-list-group-item>{{ $t('subscriptions.affiliate_system') }}</b-list-group-item>
              <b-list-group-item>{{ $t('subscriptions.products_exchange') }}</b-list-group-item>
              <b-list-group-item>{{ $t('subscriptions.and_more_to_come_soon') }}</b-list-group-item>
            </b-list-group>
            <!--/ plan benefit -->
          </b-overlay>

          <!-- buttons -->
          <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              block
              class="mt-2"
              variant="outline-primary"
              :to="{name: 'contacts'}"
          >
            {{ $t('buttons.contact_us') }}
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <div class="pricing-faq">
      <h3 class="text-center">
        {{ $t('titles.faq') }}
      </h3>
      <p class="text-center">
        {{ $t('faq.let_us_help_answer_the_most_common_questions') }}
      </p>
      <b-row class="py-2">
        <b-col
            class="mx-auto mb-4"
        >
          <app-collapse
              accordion
              type="margin"
          >

            <app-collapse-item
                v-for="(data,index) in questions"
                :key="index"
                :title="data.title"
            >
              {{ data.answer }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {BContainer, BCard, BButton, BRow, BCol, BCardText, BListGroup, BListGroupItem, BOverlay} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BContainer,
    BCard,
    BButton,
    BRow,
    BCol,
    BCardText,
    BListGroup,
    BListGroupItem,
    BOverlay,
    AppCollapse,
    AppCollapseItem
  },
  directives: {
    Ripple,
  },
  props: {
    embedded: {
      type: Boolean,
      default: false
    }
  },
  name: "Prices",
  metaInfo() {
    if (this.embedded) {
      return;
    }
    return {
      title: this.query || this.$t('page_titles.price_plans')
    }
  },
  data() {
    return {
      questions: [
        {
          title: this.$t('faq.is_the_free_plan_really_free'),
          answer: this.$t('faq.yes_it_is_our_philosophy_is_that_everyone_should_h')
        },
        {
          title: this.$t('faq.can_i_upgrade_my_current_plan_whenever_i_wish'),
          answer: this.$t('faq.yes_you_can_contact_us_and_we_will_find_a_best_sol')
        },
        {
          title: this.$t('faq.are_there_any_additional_features_that_will_be_add'),
          answer: this.$t('faq.yes_we_are_constantly_adding_new_features_dependin')
        }
      ]
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.data.id
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';

.free-plan-container {
  zoom: 1.1;
}
</style>